import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://74349785fc7cf0a6339a5db5f9f0346d@o4507013032574976.ingest.us.sentry.io/4507013036048384",
  debug: false,
  environment: "production",
  release: "dashboard@0.0.1",
  tracesSampleRate: 1,
  sampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});